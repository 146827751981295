// Icon for newsletter in admin menu
#menub9cf245e-e5c2-4bb1-abf2-7845823ccd4e:before {
  content: '\f0a1';
  font-family: "FontAwesome";
  color: #5cbb56;
  font-weight: normal;
  margin-right: .5em;
}


// Overlap menu over carousel
// @media (min-width: 1025px) {
// .menuMain {
//     position: relative;
//     transform: translateY(70%);
// }
// }

// White Nav border-left
// @media (min-width: 1025px) {
//   .menuMain .topLevel li.level1>a {
//     border-left: 2px solid #fff;
//   }
//   .menuMain .topLevel li.level1>a:hover {
//     border-left: 2px solid $brand-primary;
//   }
//   .menuMain .subMenu.level2 {
//     border-left: 2px solid $brand-primary;
//   }
// }

// Pageheader border-bottom
.pageHeader {
  border-bottom: 4px solid $brand-primary;
}

// Use brand font only on carousel
.carouselSlideHeading {
  font-family: 'BlueberryJam', sans-serif;
}

// Keep Nav chevrons brand green
li.level1.hasSubmenu:hover>a:after,
ul.subMenu .hasSubmenu:hover>a:after {
  color: $brand-primary;
}

// Letter spacing on carousel
.carouselSlideHeading, h1.title {
  letter-spacing: 0.2rem;
}


// Circle on impact stat figures

.homefeaturecategory-homeboximpactstats {
  td:first-child h2 {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 225px;
      width: 225px;
      background-image: url($assets-path + "roundel_fullop.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// Stats size
@media (max-width: map-get($breakpoints, lg)) {
  .homefeaturecategory-homeboximpactstats td:first-child h2 {
    font-size: 5vw;
  }
}
@media (max-width: map-get($breakpoints, md)) {
  .homefeaturecategory-homeboximpactstats td:first-child h2 {
    font-size: 13.75vw;
  }
}


// .homefeaturecategory-homeboximpactstats {
//   td:first-child h2 {
//       border: 4px solid $brand-secondary;
//       width: 200px;
//       height: 200px;
//       border-radius: 200px;
//       display: inline-flex;
//       justify-content: center;
//       align-items: center;
//   }
// }

// Stats
.homefeaturecategory-homeboximpactstats {
  position: relative;
}

.homefeaturecategory-homeboximpactstats:after {
  content: "";
  display: block;
  position: absolute;
  background-image: url("../assets/tree.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
  right: 25%;
  width: 293px;
  height: 350px;
  z-index: 1;
}

@media (max-width: 600px) {
  .homeFeedBox1 .feedList:after {
    width: 180px;
    height: 80px;
  }
}



.newsListingPage .listedPost footer p.publishDatesWrapper {
  display: block!important;
}


// Footer

// Lose underlines from footer links and style footerlinks
.pageFooter ul li {
  border-bottom: 1px solid $grey-light;
  padding: 5px 0;
}
.pageFooter a:not(.button) {
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
}
// But not the social icons
.pageFooter .socialIcons li {
  border-bottom: none;
}
.pageFooter .socialIcons li a:hover {
  text-decoration: none;
}

// Lose newsletter admin link from footer
.footerBox #menub9cf245e-e5c2-4bb1-abf2-7845823ccd4e {
  display: none;
}

// Fix possible bug in 5-box, prefab-4 layout on mobile
@media (max-width: 576px) {
  .footerBox1 {
    grid-column: 3 / span 12;
  }
}

// Footer Logos

.footerBox.Footerlogos {
  width: 100%;
  margin: 30px auto 0;
  padding: 20px 0;
  background-color: #fff;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.logoContainer a {
  margin: 20px 20px;
}
.logoContainer a img {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
}

// In-page Logo List
ul.logo-list {
  list-style: none;
  width: 100%;
  margin: 30px auto 0;
  padding: 20px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  li a {
    margin: 20px 20px;
  }
  li a img {
    max-width: 200px;
    max-height: 200px;
  }
}


// JK - https://www.bugherd.com/projects/172310/tasks/63
// Slim down email font size in footer so it can fit
.pageFooter a[href*="mailto"] {
  font-size: .9em;
}



// Shop. Not sure why removing not(.listedProduct) from prefabs isn't working..

// .listedProduct {
//   width: calc( (100% - 120px) / 3 - 1px);
// }


// Hide Breadcrumbs on FAQs
body.faqsBody .breadcrumbWrapper {
  display: none;
}

// Justify terms and conditions on checkout DES-4745
.terms-and-conditions-wrapper {
  text-align: left;
}

// Associated Appeal
.postAside .associatedBox,
.postAside .associatedAppeal {
  .banner { display: none; }
  h2 { font-size: 1.75em; }
  .postSummary { font-size: $font-size-small; }
}
.postAside .associatedAppeal {
  background-color: #F0F0F0;
}
.postAside .associatedBox {
  background-color: $context-container-background-colour;
  .associatedTitle a, .associatedSummary {
    color: text-contrast($context-container-background-colour);
  }
}


// Fundraise button
.button[class*='underaise'], .callToActionBtn.fundraise, .findAFundraiser .setUpFundraiserBtn {
  @include button (
    $btn-background-colour: $brand-secondary
  )
}

// Sidebar - Format
.post .contentBlock .postAside > * {
  border-radius: $border-radius;
}

.post .contentBlock .postAside .content.postFeeds:not(.eventDetails) {
  padding: 0;
}

// Sidebar off for non-event and non-appeals posts
.pageWrapper.pagePostPage,
.pageWrapper.newsPostPage,
.pageWrapper.blogPostPage {
  .post .contentBlock {
    display: block;
  }
}

// CR-259
body.PostCategory_hide-share-buttons .shareWrapper {
  display: none;
}
