//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 13
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card (
    $card-gap-width: 10px,
    $card-border: none,
    $card-heading-colour: #fff,
    $card-summary-colour: #fff,
    $card-details-background-colour: rgba($brand-primary, 0.8),
    $card-hover-details-background-colour: $brand-primary,
    $card-hover-heading-colour: #fff,
    $card-hover-summary-colour: #fff,
    $card-summary-enabled: true,
    $card-hover-image-opacity: 1
  );
  @include card-text-overlay (
    $card-text-overlay-show-summary-on-hover: true
  );
}
// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 14
  );
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 13
  );
}

// Choose your card style for home feed cards
.homeFeedBox1 .feedItem {
  @include card (
    $card-gap-width: 10px,
    $card-border: none,
    $card-heading-colour: #fff,
    $card-summary-colour: #fff,
    $card-details-background-colour: rgba($brand-primary, 0.8),
    $card-hover-details-background-colour: $brand-primary,
    $card-hover-heading-colour: #fff,
    $card-hover-summary-colour: #fff,
    $card-summary-enabled: true,
    $card-hover-image-opacity: 1
  );
  @include card-text-overlay (
    $card-text-overlay-show-summary-on-hover: true
  );
}

.homeFeedBox2 .feedItem {
  @include card (
    $card-gap-width: 10px,
    $card-border: none,
    $card-heading-colour: #fff,
    $card-summary-colour: #fff,
    $card-details-background-colour: rgba($brand-primary, 0.8),
    $card-hover-details-background-colour: $brand-primary,
    $card-hover-heading-colour: #fff,
    $card-hover-summary-colour: #fff,
    $card-summary-enabled: true,
    $card-hover-image-opacity: 1
  );
  @include card-text-overlay (
    $card-text-overlay-show-summary-on-hover: true
  );
}


// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card(
    $card-footer-on: true,
    $card-footer-background-colour: transparent
  );
  @include card-basic (
  );
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
