$platform-name: 'neneparktrust';

@font-face {
  font-family: 'RooneyRegular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/RooneyPro-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'BWSurco';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/branding_with_type_-_bwsurco-regular-webfont.woff')
      format('woff');
}
@font-face {
  font-family: 'BWSurco';
  font-style: bold;
  font-weight: 600;
  src: url('../assets/branding_with_type_-_bw_surco_bold-webfont.woff')
      format('woff');
}
@font-face {
  font-family: 'BlueberryJam';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/hanoded_-_blueberry_jam_regular-webfont.woff')
      format('woff');
}

// OTF
@font-face {
  font-family: 'BWSurco';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/BwSurcoRegular.otf')
      format('otf');
}
@font-face {
  font-family: 'BWSurco';
  font-style: bold;
  font-weight: 600;
  src: url('../assets/BwSurcoBold.otf')
      format('otf');
}
@font-face {
  font-family: 'BlueberryJam';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/BlueberryJamRegular.otf')
      format('otf');
}

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
  xl: 1400px
);

// Create greyscale
$black: #292b2c !default;
$grey-dark: #333333 !default;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #eceeef !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

$brand-primary: #3C9F1A ;
$brand-primary-text-contrast-override: $white;
$brand-secondary: #00aeef;
$brand-primary-passAA: #388334;
$brand-secondary-passAA: #00aeef;
$brand-grey: #54575A;
$donate-colour: $brand-primary;
$black: #292b2c;
$link-colour: $brand-primary;

// layout
$container-max-width: 1230px;
$gap-width: 40px;
$site-bleed: 20px;
$border-radius: 5px;

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Buttons
$btn-background-colour: $brand-primary;
$cta-btn-background-colour: $brand-secondary;
$btn-text-colour: #fff;
$btn-border-radius: 3px;
$btn-font-weight: 700;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;

$donate-btn-colour: #fff;

// Typography
$font-family-base: 'Manrope', sans-serif;
$headings-font-family: 'RooneyRegular', sans-serif;
//$headings-font-family: 'BlueberryJam', sans-serif;
$headings-margin-top: .5em;

// Form elements
// $input-border-radius: 20px;

// Header
$header-content-margin-top: 0;

// Menu admin
$menu-admin-enabled: true;
//$menu-admin-absolute: true;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-background-colour: transparent;
$menu-admin-to-nav-breakpoint: 380px;

// Search
$header-search-enabled: false;

// Social network colours
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $grey-light;
$social-icons-header-hover-colour: $brand-primary;

// Logo
$logo: 'NPT-logo.png';
$logo-width: 479px;
$logo-height: 77px;

// Navigation

// White nav
$nav-normal-margin-bottom: 0;
$nav-background-colour: transparent;
$nav-normal-align-items: center;
$nav-top-level-item-colour: $brand-grey;
$nav-top-level-chevrons-colour: $brand-primary;
$nav-top-level-item-hover-colour: $brand-grey;
$nav-top-level-item-hover-background-colour: #E6FFE4;
$nav-top-level-chevrons-enabled: true;
$nav-submenu-background-colour: #fff;
$nav-submenu-item-colour: $brand-grey;
$nav-submenu-chevrons-colour: $brand-primary;
$nav-submenu-item-hover-background-colour: #E6FFE4;
$nav-submenu-box-shadow: 5px 11px 8px -4px rgba(0,0,0,0.2);

// Mobile nav
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: #fff;
$nav-normal-mobile-top-level-item-colour: $brand-grey;
$nav-normal-mobile-chevrons-colour: $brand-primary;
$nav-normal-mobile-submenu-background-colour: rgba($brand-primary, 0.2);


// White nav border-left
// $nav-normal-margin-bottom: 0;
// $nav-background-colour: transparent;
// $nav-top-level-item-colour: $brand-primary-passAA;
// $nav-top-level-chevrons-colour: $brand-primary;
// $nav-top-level-item-hover-colour: $brand-primary-passAA;
// $nav-top-level-item-hover-background-colour: #fff;
// $nav-top-level-chevrons-enabled: true;
// $nav-submenu-background-colour: #fff;
// $nav-submenu-item-colour: $brand-primary-passAA;
// $nav-submenu-chevrons-colour: $brand-primary;
// $nav-submenu-item-hover-background-colour: #E6FFE4;

// Green nav AA
// $nav-background-colour: $brand-primary-passAA;
// $nav-top-level-item-colour: #fff;
// $nav-top-level-chevrons-enabled: true;
// $nav-top-level-item-padding: 10px 15px;
// $nav-submenu-background-colour: $brand-primary-passAA;
// $nav-submenu-item-colour: #fff;

// Grey nav
// $nav-background-colour: $brand-grey;
// $nav-top-level-item-colour: #fff;
// $nav-top-level-chevrons-enabled: true;
// $nav-top-level-item-padding: 10px 15px;
// $nav-submenu-background-colour: $brand-grey;
// $nav-submenu-item-colour: #fff;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $brand-primary-passAA;
// $burger-btn-text-transform: $btn-text-transform !default;
$burger-btn-icon-breakpoint: map-get(
  $breakpoints,
  lg
); // What viewport width do you want to remove the 'menu' text from the burger button?
$burger-btn-bar-breakpoint: null !default; // What viewport width do you want the burger button to pop under the header full width?


// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(to right, #000 0%, rgba(0,0,0,0) 70%);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 600px;
$carousel-heading-colour: #fff;
$carousel-heading-font-size: 6em;
$carousel-summary-colour: #fff;
$carousel-summary-enabled: false;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $spacer-x;
$carousel-controls-icon-colour: #fff;
$carousel-controls-button-border-radius: 20px;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $grey-lightest;
$carousel-mobile-heading-colour: $brand-primary;
$carousel-mobile-summary-colour: $brand-grey;

// Carousel dots
$carousel-dots-colour: #caecc7;
$carousel-dots-opacity: 1;


// Home Intro
$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 3;
$home-intro-background-colour: transparent;
$home-intro-colour: $black;
$home-intro-font-size: 1.3em;
$home-intro-max-width: 900px;

// Home Features
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;
$home-features-cards-entry-animation-prefab: 2;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;

// Home Feeds
$feed-item-event-location-enabled: false;
$event-card-floating-date: true;
$home-feeds-cards-entry-animation-prefab: 2;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;


// Impact stats
$impact-stats-figure-font-family: 'BlueberryJam', sans-serif;
$impact-stats-figure-font-size: 3.75em;
$impact-stats-padding-y: $spacer;
$impact-stats-figure-colour: #fff;
$impact-stats-background-colour: $brand-secondary;
$impact-stats-margin-top: 3em;
// $impact-stats-summary-margin-top: 0;
$impact-stats-summary-margin-top: 5em;
$impact-stat-padding: 1.8rem 0;

// Stats pic bg
// $impact-stats-figure-font-family: 'BlueberryJam', sans-serif;
// $impact-stats-figure-font-size: 5em;
// $impact-stats-padding-y: $spacer * 5;
// $impact-stats-figure-colour: #fff;
// $impact-stats-background-colour: $black;
// $impact-stats-background-image: "statsBg.jpg";
// $impact-stats-background-image-opacity: 0.4;
// $impact-stats-margin-top: $spacer-x * 4;


// Home Quickgiving
$home-quick-giving-background-colour: $brand-primary;
$home-quick-giving-colour: #fff;
$home-quick-giving-padding-y: $spacer * 2;
$home-quick-giving-donation-amount-background-colour: darken($brand-secondary, 37.5%);
$home-quick-giving-donate-btn-background-colour: #fff;
$home-quick-giving-donate-btn-colour: $brand-primary;


// Footer
$footer-boxes-count: 5;
$footer-prefab: 4;
// $footer-background-colour: #292b2c;
$footer-background-colour: darken($brand-secondary, 37.5%);

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: #fff;
$newsletter-heading-text-align: left;
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: #fff;


// Posts

$page-title-font-family: 'RooneyRegular', sans-serif;
$page-title-font-size: 5em;
$page-title-colour: $brand-primary;
$page-title-margin-bottom: $spacer * 2;

// Blockquotes
$blockquote-background-colour: $brand-secondary;
$blockquote-colour: #fff;
$blockquote-padding: $spacer * 4;
$blockquote-font-size: 1.2em;
$blockquote-text-align: left;

// Fundraising
$fundraising-enabled: true;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: true;

// Top fundraisers
$top-fundraisers-enabled: false;
$top-fundraisers-profile-pics-enabled: false;



// Sidebars - off
$sidebar-enabled: true;
$post-content-max-width: 1024px;
$sidebar-layout: adjacent;
$sidebar-adjacent-width: 275px;
$sidebar-adjacent-gap: $spacer * 5;
$sidebar-background-colour: $grey-lightest;
$sidebar-padding: $spacer;

// Sidebar - content
$associated-latest-enabled: false;
$associated-related-enabled: false;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 0;
$associated-item-image-enabled: false;
$associated-item-heading-colour: $white;
$associated-item-heading-font-size: 1.75em;
$associated-item-summary-enabled: false;
$associated-item-border: true;

// Listing pages
$listing-intro-max-width: 980px;

// Donation amounts
$donation-amount-min-width: 220px;

// Blog details
$blog-details-enabled: false;